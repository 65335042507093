import React from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"

const SvgAnalysis = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 872 650"}>
      <path
        data-name="Rectangle 9"
        fill="#ebebeb"
        d="M0 571.286h872.459v.436H0z"
      />
      <path
        data-name="Rectangle 10"
        fill="#ebebeb"
        d="M579.505 583.169h170.304v.436H579.505z"
      />
      <path
        data-name="Rectangle 11"
        fill="#ebebeb"
        d="M504.282 593.255h81.435v.436h-81.435z"
      />
      <path
        data-name="Rectangle 12"
        fill="#ebebeb"
        d="M751.554 599.362h33.485v.436h-33.485z"
      />
      <path
        data-name="Rectangle 13"
        fill="#ebebeb"
        d="M220.68 593.464h161.457v.436H220.68z"
      />
      <path
        data-name="Rectangle 14"
        fill="#ebebeb"
        d="M170.426 593.464h34.724v.436h-34.724z"
      />
      <path
        data-name="Rectangle 15"
        fill="#ebebeb"
        d="M100.839 586.101h57.338v.436h-57.338z"
      />
      <path
        data-name="Tracé 21"
        d="M413.545 493.463H76.619a9.964 9.964 0 0 1-9.946-9.963V9.876A9.963 9.963 0 0 1 76.619 0h336.926a9.963 9.963 0 0 1 9.963 9.964V483.5a9.963 9.963 0 0 1-9.963 9.963zM76.619.349a9.527 9.527 0 0 0-9.51 9.527V483.5a9.527 9.527 0 0 0 9.51 9.527h336.926a9.545 9.545 0 0 0 9.527-9.527V9.876a9.545 9.545 0 0 0-9.527-9.527z"
        fill="#ebebeb"
      />
      <path
        data-name="Tracé 22"
        d="M790.989 493.463H454.046a9.981 9.981 0 0 1-9.964-9.963V9.876A9.981 9.981 0 0 1 454.046 0h336.943a9.963 9.963 0 0 1 9.929 9.876V483.5a9.963 9.963 0 0 1-9.929 9.963zM454.046.349a9.545 9.545 0 0 0-9.527 9.527V483.5a9.545 9.545 0 0 0 9.527 9.527h336.943a9.545 9.545 0 0 0 9.527-9.527V9.876a9.545 9.545 0 0 0-9.527-9.527z"
        fill="#ebebeb"
      />
      <path
        data-name="Rectangle 16"
        fill="#ebebeb"
        d="M299.986 353.643H93.422v-13.384h206.564z"
      />
      <path
        data-name="Rectangle 17"
        fill="#ebebeb"
        d="M283.549 571.286H97.506V353.642h186.043z"
      />
      <path
        data-name="Rectangle 18"
        fill="#f0f0f0"
        d="M283.549 353.643h4.607v217.644h-4.607z"
      />
      <path
        data-name="Tracé 23"
        d="M283.55 353.643H97.489v9.3l186.061-4.659z"
        fill="#e6e6e6"
      />
      <path
        data-name="Rectangle 19"
        fill="#ebebeb"
        d="M641.833 571.286H455.79V353.642h186.043z"
      />
      <path
        data-name="Rectangle 20"
        fill="#f0f0f0"
        d="M641.833 353.643h4.607v217.644h-4.607z"
      />
      <path
        data-name="Tracé 24"
        d="M641.833 353.643H455.79v9.3l186.043-4.659z"
        fill="#e6e6e6"
      />
      <path
        data-name="Rectangle 21"
        fill="#f5f5f5"
        d="M299.986 340.259h361.39v13.384h-361.39z"
      />
      <path
        data-name="Tracé 25"
        d="M253.397 308.257h183.426a12.319 12.319 0 0 0 12.441-14.273l-14.168-111.029a16.751 16.751 0 0 0-16.088-14.273H235.564a12.337 12.337 0 0 0-12.459 14.273l14.2 111.029a16.769 16.769 0 0 0 16.088 14.273z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 26"
        d="M315.325 250.099h40.918l-13.855 90.16H301.47z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 27"
        d="M255.875 308.257h183.409a12.319 12.319 0 0 0 12.46-14.273l-14.17-111.029a16.769 16.769 0 0 0-16.106-14.273H238.044a12.319 12.319 0 0 0-12.441 14.273l14.169 111.029a16.769 16.769 0 0 0 16.106 14.273z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 28"
        d="M327.678 168.681h26.75l48.142 109.23h-26.767z"
        fill="#f5f5f5"
      />
      <path
        data-name="Tracé 29"
        d="M309.426 168.681h10.714l48.125 109.23h-10.714z"
        fill="#f5f5f5"
      />
      <path
        data-name="Tracé 30"
        d="M449.688 277.913l2.059 16.071a12.337 12.337 0 0 1-12.459 14.273H255.875a16.769 16.769 0 0 1-16.106-14.273l-2.041-16.071z"
        fill="#f0f0f0"
      />
      <path
        data-name="Tracé 31"
        d="M183.46 310.718a48.107 48.107 0 0 1-1.134-21.166 65.277 65.277 0 0 1 6.613-20.416c.768-1.623 1.745-3.106 2.67-4.676l1.413-2.286a49.628 49.628 0 0 1 1.588-2.181c1.1-1.413 2.164-2.844 3.28-4.24s2.46-2.6 3.7-3.874l1.885-1.9c.646-.593 1.344-1.152 2.042-1.745a150.667 150.667 0 0 1 4.153-3.315 79.736 79.736 0 0 1 9.074-5.409c-2.076 2.914-4.45 5.392-6.561 8.079a125.376 125.376 0 0 1-3.123 4.031c-.506.681-1.064 1.291-1.57 1.972l-1.431 2.076c-.96 1.361-1.937 2.722-2.931 4.048s-1.745 2.827-2.635 4.223c-.436.7-.925 1.378-1.344 2.076l-1.187 2.181c-.785 1.448-1.745 2.827-2.373 4.327a114.332 114.332 0 0 0-7.538 18.3 118.082 118.082 0 0 0-4.589 19.892z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 32"
        d="M146.766 305.623a121.031 121.031 0 0 0-4.362-19.91 115.758 115.758 0 0 0-7.329-18.391c-.663-1.5-1.57-2.9-2.321-4.345l-1.169-2.2c-.4-.715-.89-1.378-1.309-2.094-.872-1.413-1.745-2.809-2.6-4.24s-1.937-2.7-2.879-4.1l-1.413-2.076c-.489-.7-1.047-1.326-1.553-1.989a190.177 190.177 0 0 1-3.071-4.066c-2.076-2.722-4.415-5.235-6.474-8.166a77.638 77.638 0 0 1 9.021 5.531 147.99 147.99 0 0 1 4.118 3.35c.681.558 1.378 1.117 2.024 1.745l1.85 1.9q1.867 1.937 3.664 3.926c1.1 1.4 2.146 2.844 3.228 4.275.523.733 1.064 1.448 1.553 2.2l1.4 2.3c.872 1.57 1.867 3.071 2.617 4.711a65.364 65.364 0 0 1 6.369 20.433 48.055 48.055 0 0 1-1.361 21.2z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 33"
        d="M147.323 308.851c.192-7.887 0-15.512-.506-23.068a155.134 155.134 0 0 0-3.263-22.3c-.663-3.682-1.85-7.207-2.792-10.836-.593-1.745-1.239-3.49-1.745-5.235a55.84 55.84 0 0 0-2.024-5.235c-2.635-7.1-6.474-13.628-10.121-20.677a64.562 64.562 0 0 1 15.391 18.077l1.536 2.582a28.054 28.054 0 0 1 1.291 2.722c.855 1.745 1.745 3.664 2.443 5.514a92.2 92.2 0 0 1 3.33 11.343 84.593 84.593 0 0 1 1.745 23.87 69.325 69.325 0 0 1-5.287 23.242z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 34"
        d="M180.459 313.492a108.185 108.185 0 0 1-9.649-22.841 155.082 155.082 0 0 1-5.235-24.237c-.611-4.083-.907-8.219-1.239-12.354a92.233 92.233 0 0 1-.14-12.424 110.436 110.436 0 0 1 3.315-24.586c1.1 8.271 1.57 16.332 2.582 24.429.349 4.013.925 8.009 1.326 12.005l.785 5.985c.209 2.007.489 4 .785 5.985q1.623 11.953 3.595 23.905z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 35"
        d="M178.82 311.905a108.045 108.045 0 0 1-12.214-21.6 151.669 151.669 0 0 1-8.009-23.452c-1.1-4-1.867-8.079-2.652-12.214a95.471 95.471 0 0 1-1.553-12.319 110.156 110.156 0 0 1 .471-24.8c2.024 8.079 3.49 16.036 5.235 23.905.82 3.943 1.85 7.852 2.7 11.778l1.466 5.863c.436 1.954.942 3.909 1.466 5.845 1.972 7.8 4.1 15.565 6.3 23.347z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 36"
        d="M175.591 310.141a107.558 107.558 0 0 1-14.169-20.346 151.809 151.809 0 0 1-10.208-22.684c-1.461-3.87-2.599-7.87-3.768-11.83a95.221 95.221 0 0 1-2.722-12.127 109.933 109.933 0 0 1-1.871-24.743c2.774 7.852 4.921 15.7 7.555 23.312 1.187 3.856 2.582 7.643 3.8 11.464l2.007 5.706c.611 1.9 1.309 3.786 2.024 5.671 2.687 7.59 5.531 15.128 8.48 22.684z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 37"
        d="M184.595 308.048a48.177 48.177 0 0 1-5.043-20.59 65.626 65.626 0 0 1 2.7-21.288c.454-1.745 1.187-3.4 1.745-5.1l.96-2.513c.366-.82.768-1.623 1.169-2.425.8-1.605 1.588-3.211 2.425-4.781s1.937-3.019 2.914-4.5l1.5-2.216a23.723 23.723 0 0 1 1.745-2.059c1.152-1.361 2.3-2.7 3.49-4.031a75.212 75.212 0 0 1 7.922-6.98c-1.518 3.246-3.385 6.125-4.956 9.161a220.437 220.437 0 0 1-2.321 4.537c-.384.75-.82 1.466-1.187 2.233l-1.012 2.3c-.7 1.518-1.4 3.036-2.129 4.519a41.409 41.409 0 0 0-1.745 4.641c-.3.768-.663 1.518-.925 2.286l-.768 2.356c-.506 1.57-1.152 3.106-1.536 4.694a115.515 115.515 0 0 0-4.013 19.386 119.055 119.055 0 0 0-.935 20.37z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 38"
        d="M184.595 311.224a68.647 68.647 0 0 1-9.3-21.794 84.575 84.575 0 0 1-2.373-23.818 88.77 88.77 0 0 1 1.326-11.97c.454-1.972.925-3.926 1.448-5.863.279-.96.454-1.954.8-2.9l1.064-2.827a64.649 64.649 0 0 1 11.991-20.447 192.4 192.4 0 0 0-6.334 22.091 49.89 49.89 0 0 0-1.064 5.531c-.262 1.85-.611 3.682-.89 5.531-.279 3.734-.838 7.416-.855 11.167a159.208 159.208 0 0 0 .663 22.492 227.357 227.357 0 0 0 3.524 22.807z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 39"
        d="M172.66 301.243l-21.061-35.893-2.6 34.968z"
        fill="#e6e6e6"
      />
      <path
        data-name="Tracé 40"
        d="M207.314 291.576h-80.912l10.592 48.683h59.728z"
        fill="#f5f5f5"
      />
      <path
        data-name="Tracé 41"
        d="M156.762 291.576h-30.36l10.592 48.683h15.948z"
        fill="#ebebeb"
      />
      <path
        data-name="Tracé 42"
        d="M727.16 402.692H598.507c-6.875-60.793 5.758-126.14 37.271-187.195a9.876 9.876 0 0 1 8.725-5.339H753.33a8.515 8.515 0 0 1 7.451 12.668c-28.84 58.926-40.234 121.499-33.621 179.866z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 43"
        d="M696.641 386.692H554.884a16.036 16.036 0 0 0-16 16h173.793a16.036 16.036 0 0 0-16.036-16z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 44"
        d="M633.633 571.286h-7.887l-19.491-168.594h7.887z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 45"
        d="M746.651 571.286h-7.888l-19.49-168.594h7.89z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 46"
        d="M644.137 571.286h7.9l19.495-168.594h-7.9z"
        fill="#e0e0e0"
      />
      <path
        data-name="Tracé 47"
        d="M531.136 571.286h7.887l19.491-168.594h-7.887z"
        fill="#e0e0e0"
      />
      <path
        data-name="Rectangle 22"
        fill="#f5f5f5"
        d="M147.027 23.26h594.18v109.354h-594.18z"
      />
      <path
        data-name="Rectangle 23"
        fill="#ebebeb"
        d="M147.027 132.614h-4.484V23.26h4.484z"
      />
      <path
        data-name="Tracé 48"
        d="M705.924 88.415c-174.108 29.315-348.216-29.315-522.324 0V67.476c174.108-29.315 348.216 29.315 522.324 0z"
        fill="#ebebeb"
      />
      <g transform="translate(97.907 610.582)">
        <ellipse
          cx="338.322"
          cy="19.752"
          rx="338.322"
          ry="19.752"
          fill="#f5f5f5"
        />
      </g>
      <motion.g animate={{y: [10, -10, 10]}} transition={{loop: Infinity, duration: 3}}>
        <path
          data-name="Tracé 49"
          d="M659.579 78.312h50.83c10.121 0 18.339 11.848 12.459 23.7H521.333c6.98-13.959-5.5-23.7-12.476-23.7z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 50"
          d="M499.465 78.312h206.773c-28.32 0-53.569 22.946-56.431 51.248L613.164 492.8c-2.862 28.3-28.111 51.248-56.431 51.248H452.509c-28.3 0-48.858-22.946-46.083-51.248l36.643-363.24c2.83-28.355 28.059-51.248 56.396-51.248z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 51"
          d="M499.465 78.312h206.773c-28.32 0-53.569 22.946-56.431 51.248L613.164 492.8c-2.862 28.3-28.111 51.248-56.431 51.248H452.509c-28.3 0-48.858-22.946-46.083-51.248l36.643-363.24c2.83-28.355 28.059-51.248 56.396-51.248z"
          fill="#fff"
          opacity=".7"
        />
        <path
          data-name="Tracé 52"
          d="M510.686 492.8a14.867 14.867 0 0 0-15.059-16.751H288.854A14.867 14.867 0 0 1 303.93 492.8c-2.862 28.3 17.781 51.248 46.083 51.248h206.756c-28.303 0-48.997-22.946-46.083-51.248z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 53"
          d="M299.759 476.031H248.93a17.118 17.118 0 0 0-17.048 16.769h246.7a17.118 17.118 0 0 1 17.048-16.769z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 54"
          d="M299.759 476.031H248.93a17.118 17.118 0 0 0-17.048 16.769h246.7a17.118 17.118 0 0 1 17.048-16.769z"
          fill="#fff"
          opacity=".6"
        />
        <g data-name="Groupe 4" opacity=".5" fill="#fff">
          <path
            data-name="Tracé 55"
            d="M476.485 256.381h-15.6l1.588-15.582h15.582z"
          />
          <path
            data-name="Tracé 56"
            d="M502.222 256.381H486.64l1.57-15.582h15.582z"
          />
          <path
            data-name="Tracé 57"
            d="M527.977 256.381h-15.6l1.57-15.582h15.6z"
          />
          <path
            data-name="Tracé 58"
            d="M553.715 256.381h-15.6l1.588-15.582h15.582z"
          />
          <path
            data-name="Tracé 59"
            d="M579.452 256.381H563.87l1.57-15.582h15.58z"
          />
          <path
            data-name="Tracé 60"
            d="M605.207 256.381h-15.6l1.57-15.582h15.6z"
          />
          <path
            data-name="Tracé 61"
            d="M473.884 282.137h-15.582l1.57-15.6h15.58z"
          />
          <path
            data-name="Tracé 62"
            d="M499.64 282.137h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 63"
            d="M525.377 282.137h-15.6l1.588-15.6h15.582z"
          />
          <path
            data-name="Tracé 64"
            d="M551.114 282.137h-15.582l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 65"
            d="M576.87 282.137h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 66"
            d="M602.607 282.137h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 67"
            d="M471.302 307.874h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 68"
            d="M497.04 307.874h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 69"
            d="M522.777 307.874h-15.582l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 70"
            d="M548.532 307.874h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 71"
            d="M574.27 307.874h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 72"
            d="M600.007 307.874h-15.582l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 73"
            d="M468.702 333.611h-15.6l1.57-15.582h15.6z"
          />
          <path
            data-name="Tracé 74"
            d="M494.439 333.611h-15.582l1.57-15.582h15.582z"
          />
          <path
            data-name="Tracé 75"
            d="M520.177 333.611h-15.582l1.57-15.582h15.582z"
          />
          <path
            data-name="Tracé 76"
            d="M545.932 333.611h-15.6l1.57-15.582h15.6z"
          />
          <path
            data-name="Tracé 77"
            d="M571.669 333.611h-15.582l1.57-15.582h15.582z"
          />
          <path
            data-name="Tracé 78"
            d="M597.407 333.611h-15.582l1.57-15.582h15.582z"
          />
          <path
            data-name="Tracé 79"
            d="M466.102 359.367H450.52l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 80"
            d="M491.839 359.367h-15.582l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 81"
            d="M517.595 359.367h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 82"
            d="M543.333 359.367h-15.6l1.588-15.6h15.582z"
          />
          <path
            data-name="Tracé 83"
            d="M569.07 359.367h-15.582l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 84"
            d="M594.825 359.367h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 85"
            d="M463.502 385.104H447.92l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 86"
            d="M489.257 385.104h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 87"
            d="M514.995 385.104h-15.6l1.588-15.6h15.582z"
          />
          <path
            data-name="Tracé 88"
            d="M540.732 385.104H525.15l1.57-15.6h15.582z"
          />
          <path
            data-name="Tracé 89"
            d="M566.488 385.104h-15.6l1.57-15.6h15.6z"
          />
          <path
            data-name="Tracé 90"
            d="M592.225 385.104h-15.6l1.588-15.6h15.582z"
          />
        </g>
        <path
          data-name="Tracé 91"
          d="M471.582 369.504a7.364 7.364 0 0 1-7.154 6.509 5.776 5.776 0 0 1-5.846-6.509 7.329 7.329 0 0 1 7.154-6.491 5.758 5.758 0 0 1 5.845 6.491z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 92"
          d="M505.119 292.274a7.381 7.381 0 0 1-7.172 6.509 5.776 5.776 0 0 1-5.88-6.509 7.381 7.381 0 0 1 7.172-6.491 5.793 5.793 0 0 1 5.88 6.491z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 93"
          d="M554.011 318.029a7.381 7.381 0 0 1-7.172 6.509 5.775 5.775 0 0 1-5.845-6.509 7.381 7.381 0 0 1 7.154-6.509 5.793 5.793 0 0 1 5.863 6.509z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 94"
          d="M610.721 266.536a7.381 7.381 0 0 1-7.172 6.509 5.776 5.776 0 0 1-5.845-6.509 7.381 7.381 0 0 1 7.172-6.509 5.776 5.776 0 0 1 5.846 6.509z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 95"
          d="M465.073 371.424a1.9 1.9 0 0 1-1.745-2.67l33.537-77.23a1.885 1.885 0 0 1 1.117-1.047 1.919 1.919 0 0 1 1.518.122l47.706 25.109 55.68-50.6a1.909 1.909 0 1 1 2.565 2.827l-56.675 51.51a1.867 1.867 0 0 1-2.164.262L499.5 294.926l-32.665 75.346a1.92 1.92 0 0 1-1.762 1.152z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 96"
          d="M457.936 440.313h-15.582l.785-7.8h15.582z"
          fill="#407bff"
          opacity=".5"
        />
        <path
          data-name="Tracé 97"
          d="M483.692 440.309h-15.6l2.7-26.854h15.6z"
          fill="#407bff"
          opacity=".6"
        />
        <path
          data-name="Tracé 98"
          d="M509.429 440.313h-15.6l1.588-15.6h15.582z"
          fill="#407bff"
          opacity=".7"
        />
        <motion.path animate={{scaleY: [1.5,1,1.1,1,1.5], y: [1.5,0,1,1.5]}} transition={{loop: Infinity, duration: 3.5}}
          data-name="Tracé 99"
          d="M535.166 440.313h-15.582l2.059-20.5h15.6z"
          fill="#407bff"
          opacity=".8"
        />
        <path
          data-name="Tracé 100"
          d="M560.922 440.313h-15.6l1.256-12.441h15.6z"
          fill="#407bff"
          opacity=".9"
        />
        <path
          data-name="Tracé 101"
          d="M586.659 440.313h-15.6l.8-7.8h15.582z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 102"
          d="M605.033 213.596H469.697a3.979 3.979 0 0 1-4.031-4.484l6.16-61.247a5.078 5.078 0 0 1 4.938-4.484h135.353a4 4 0 0 1 4.031 4.484l-6.177 61.245a5.078 5.078 0 0 1-4.938 4.484z"
          fill="#fff"
          opacity=".5"
        />
        <path
          data-name="Tracé 103"
          d="M512.221 178.488l11.2-24.08a22.684 22.684 0 0 1 14.45 24.08 29.28 29.28 0 0 1-23.731 25.249z"
          fill="#407bff"

        />
        <path
          data-name="Tracé 104"
          d="M512.22 178.488l1.919 25.249a26.173 26.173 0 0 1-4.5.384 22.858 22.858 0 0 1-23.05-25.633 29.175 29.175 0 0 1 28.166-25.633 24.167 24.167 0 0 1 8.6 1.553z"
          fill="#fff"
        />
        <path
          data-name="Tracé 105"
          d="M573.154 172.258h9.719l-3.211 31.827h-9.719z"
          fill="#fff"
        />
        <path
          data-name="Tracé 106"
          d="M587.967 189.184h9.719l-1.5 14.9h-9.719z"
          fill="#fff"
        />
        <motion.path animate={{ y: [-5,0,10,-5] }} transition={{loop: Infinity, duration: 3}}
          data-name="Tracé 107"
          d="M587.968 189.183h9.719l.925-9.091h-9.719z"
          fill="#407bff"
        />
        <motion.path animate={{ y: [10,0,10] }} transition={{loop: Infinity, duration: 4}}
          data-name="Tracé 108"
          d="M573.153 172.255h9.719l1.954-19.4h-9.719z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 109"
          d="M556.053 178.052h9.719l-2.617 26.034h-9.719z"
          fill="#fff"
        />
        <path
          data-name="Tracé 110"
          d="M556.053 178.051h9.719l1.2-11.935h-9.719z"
          fill="#407bff"
        />
      </motion.g>
      <g>
        <path
          data-name="Tracé 111"
          d="M234.342 182.434c-.768 1.274-1.745 2.879-2.5 4.345s-1.745 3.054-2.46 4.589c-1.57 3.106-3.158 6.194-4.607 9.37a162.449 162.449 0 0 0-7.538 19.194l-.733 2.443-.349 1.239-.1.3a4.589 4.589 0 0 0 .262 2.3 39.908 39.908 0 0 0 3.978 8.306 190.777 190.777 0 0 0 12.075 17.188l-5.531 5.845a98.815 98.815 0 0 1-16.751-15.7 44.9 44.9 0 0 1-6.875-10.662 19.055 19.055 0 0 1-1.745-8.358 13.134 13.134 0 0 1 .157-1.4l.122-.733v-.349l.3-1.431.611-2.862a139.991 139.991 0 0 1 7.241-21.9c1.5-3.49 3.089-6.98 4.868-10.365.872-1.745 1.745-3.385 2.722-5.06s1.9-3.263 3.071-5.078z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 112"
          d="M228.379 248.564l13.122.541-5.235 11.255s-9.335-.523-12.843-6.177z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 113"
          d="M244.739 254.165l-3.246 10.086-5.287-3.874 5.287-11.272z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 114"
          d="M255.63 615.014a19.75 19.75 0 0 1-4.223-.576.4.4 0 0 1-.3-.3.384.384 0 0 1 .157-.384c.541-.366 5.357-3.49 7.224-2.652a1.326 1.326 0 0 1 .768 1.064 2.111 2.111 0 0 1-.593 2.007 4.519 4.519 0 0 1-3.033.841zm-3.158-1.117c2.792.558 4.868.454 5.671-.279a1.361 1.361 0 0 0 .366-1.344.593.593 0 0 0-.314-.488c-.997-.436-3.769.908-5.723 2.112z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 115"
          d="M251.495 614.44a.35.35 0 0 1-.192 0 .4.4 0 0 1-.192-.332c0-.192 0-4.8 1.745-6.334a1.972 1.972 0 0 1 1.588-.506 1.361 1.361 0 0 1 1.291 1.064c.384 1.745-2.478 5.235-3.978 6.107a.384.384 0 0 1-.262.001zm2.635-6.456a1.256 1.256 0 0 0-.838.314c-1.03.925-1.344 3.49-1.413 5.043 1.5-1.221 3.28-3.8 3.036-4.9 0-.174-.14-.4-.628-.454z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 116"
          d="M373.709 607.894a.418.418 0 0 1-.349-.157.366.366 0 0 1 0-.436c.489-.628 4.781-6.02 7.1-6.177h.157a1.239 1.239 0 0 1 1.029.611 1.954 1.954 0 0 1 .157 1.9c-.907 2.129-5.427 3.49-8.044 4.24zm6.788-6.02c-1.274.087-3.961 2.792-5.793 4.991 3.49-1.03 5.9-2.3 6.421-3.49a1.309 1.309 0 0 0-.122-1.256.489.489 0 0 0-.419-.244z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 117"
          d="M373.709 607.895a.367.367 0 0 1-.3-.227c0-.157-1.588-4.083-.471-6.421a2.844 2.844 0 0 1 1.658-1.483c1.012-.366 1.535 0 1.745.314 1.064 1.448-.925 6.107-2.321 7.7a.384.384 0 0 1-.311.117zm1.448-7.643a2.022 2.022 0 0 0-.454.1 2.111 2.111 0 0 0-1.221 1.1 8.288 8.288 0 0 0 .262 5.235c1.291-1.867 2.478-5.357 1.85-6.229a.454.454 0 0 0-.436-.209z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 118"
          d="M255.072 118.183c1.745 9.492 5.863 30.239-.8 36.416 0 0 2.53 9.667 20.154 10.016 19.386.366 9.318-9.423 9.318-9.423-10.557-2.74-10.243-10.557-8.358-17.885z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 119"
          d="M249.523 159.416l-3.385-16.734a62.276 62.276 0 0 0 14.94 2.443c6.788 0 21.5-.541 28.809-3.071 2.024 2.862 12.633 10.47 12.633 10.47l-4.746 8.009z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 120"
          d="M249.523 159.416l-3.385-16.734a62.276 62.276 0 0 0 14.94 2.443c6.788 0 21.5-.541 28.809-3.071 2.024 2.862 12.633 10.47 12.633 10.47l-4.746 8.009z"
          fill="#fff"
          opacity=".8"
        />
        <path
          data-name="Tracé 121"
          d="M292.901 99.949a.855.855 0 0 1-.332-.122.768.768 0 0 1-.227-1.047 7.416 7.416 0 0 1 5.9-3.49.759.759 0 0 1 .017 1.518 5.881 5.881 0 0 0-4.642 2.827.733.733 0 0 1-.715.314z"
          fill="#263238"
        />
        <path
          data-name="Tracé 122"
          d="M297.77 108.865a34.654 34.654 0 0 0 4.729 8.219 5.514 5.514 0 0 1-4.572.872z"
          fill="#ff5652"
        />
        <path
          data-name="Tracé 123"
          d="M296.636 106.649c0 1.291.715 2.338 1.553 2.338s1.518-1.047 1.483-2.338-.715-2.338-1.553-2.338-1.483 1.047-1.483 2.338z"
          fill="#263238"
        />
        <path
          data-name="Tracé 124"
          d="M297.683 104.381l3.089-.89s-1.553 2.425-3.089.89z"
          fill="#263238"
        />
        <path
          data-name="Tracé 125"
          d="M358.458 617.963l15.477-5.322-10.923-34.427-15.46 5.339z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 126"
          d="M232.911 614.071h15.968l-1.762-36.992h-15.968z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 127"
          d="M250.133 612.222h-17.955a1.256 1.256 0 0 0-1.256 1.082l-1.85 14.2a2.617 2.617 0 0 0 2.6 2.844c6.264-.1 9.266-.471 17.153-.471 4.868 0 11.953.489 18.671.489s6.98-6.613 4.17-7.224c-12.563-2.687-14.588-6.4-18.863-9.946a4.275 4.275 0 0 0-2.67-.974z"
          fill="#263238"
        />
        <path
          data-name="Tracé 128"
          d="M371.912 606.36l-16.594 7.852a1.239 1.239 0 0 0-.715 1.518l4.275 13.331a2.617 2.617 0 0 0 3.612 1.361c5.741-2.809 8.376-4.449 15.7-7.87 4.484-2.111 13.959-6.037 20.206-8.951s3.647-8.882.8-8.2c-12.738 3.088-18.915 1.972-24.429.7a4.467 4.467 0 0 0-2.855.259z"
          fill="#263238"
        />
        <path
          data-name="Tracé 129"
          d="M363.012 578.231l5.863 18.426-15.7 4.642-5.64-17.748z"
          opacity=".2"
        />
        <path
          data-name="Tracé 130"
          d="M231.149 577.097h15.966l.907 19.054h-15.966z"
          opacity=".2"
        />
        <path
          data-name="Tracé 131"
          d="M226.682 157.688c-6.648 1.326-13.959 25.04-13.959 25.04l18.671 20.293s15.7-22.091 12.581-30.4c-3.176-8.599-9.441-16.487-17.293-14.933z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 132"
          d="M226.682 157.688c-6.648 1.326-13.959 25.04-13.959 25.04l18.671 20.293s15.7-22.091 12.581-30.4c-3.176-8.599-9.441-16.487-17.293-14.933z"
          opacity=".2"
        />
        <path
          data-name="Tracé 133"
          d="M237.117 173.079c3.368-3 5.636-2.042 7.172.419 1.483 7.119-7.643 21.777-11.325 27.325-1.641-6.733-3.49-20.922 4.153-27.744z"
          opacity=".4"
        />
        <path
          data-name="Tracé 134"
          d="M312.009 163.202c1.187 6.98 1.448 28.25-9.457 92.76l-64.928-1.2c1.256-26.366 1.378-42.628-10.941-97a190.962 190.962 0 0 1 27.587-3.089 208.8 208.8 0 0 1 29.472.593c9.44 1.029 19.8 3.088 24.429 4.066a4.868 4.868 0 0 1 3.838 3.87z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 135"
          d="M312.009 163.202c1.187 6.98 1.448 28.25-9.457 92.76l-64.928-1.2c1.256-26.366 1.378-42.628-10.941-97a190.962 190.962 0 0 1 27.587-3.089 208.8 208.8 0 0 1 29.472.593c9.44 1.029 19.8 3.088 24.429 4.066a4.868 4.868 0 0 1 3.838 3.87z"
          opacity=".2"
        />
        <path
          data-name="Tracé 136"
          d="M253.222 102.374c1.239 15.826 1.431 22.544 9.545 30.641 12.214 12.214 31.409 7.468 34.9-8.341 3.158-14.239.593-37.725-14.989-43.989a21.323 21.323 0 0 0-29.454 21.689z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 137"
          d="M257.41 73.286c-17.17 1.274-21.148 21.445-14.465 34.323-10.644 16.193-9.353 22.038-5.95 25.807 5.845 6.526 35.928 14.378 53.935.82 8.044-6.055-6.858-27.587-6.858-27.587s8.672-36.032-26.662-33.363z"
          fill="#263238"
        />
        <path
          data-name="Tracé 138"
          d="M238.635 134.062c-4.868 0-6.6-2.408-7.154-4.537-1.745-6.98 6.98-20.52 15.7-24.272a.96.96 0 0 1 .768 1.745c-7.73 3.333-16.193 16.088-14.6 22.038.646 2.443 3.019 3.49 6.98 3a.96.96 0 0 1 1.047.855.942.942 0 0 1-.838 1.047 18.27 18.27 0 0 1-1.903.124z"
          fill="#263238"
        />
        <path
          data-name="Tracé 139"
          d="M264.546 113.803l-18.9.628a5.514 5.514 0 0 1-5.706-5.566 5.968 5.968 0 0 1 5.688-5.933l18.9-.628a5.514 5.514 0 0 1 5.705 5.569 5.968 5.968 0 0 1-5.688 5.933z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 140"
          d="M259.539 79.045c6.439-10.016 23.295-13.035 33.956-6.753 12.476 7.346 12.214 22.091-8.847 30.344-22.91 9.039-25.109-23.591-25.109-23.591z"
          fill="#263238"
        />
        <path
          data-name="Tracé 141"
          d="M288.208 102.461a15.844 15.844 0 0 1 5.1 8.445c.942 4.17-1.745 3.612-4.833 1.9-2.792-1.536-7.154-4.572-6.98-8.044s3.973-4.656 6.713-2.301z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 142"
          d="M285.402 255.613s-9.894 109.337-19.875 170.88c-8.062 49.7-14.884 165.977-14.884 165.977h-21.779s-7.1-113.42-2.879-162.731c10.714-124.57-11.5-135.179 11.639-175.05z"
          fill="#263238"
        />
        <path
          data-name="Tracé 143"
          d="M263.256 292.169c5.671 8.341 13.244 17.955 15.7 28.111-1.954 18.444-4.24 38.842-6.718 58.455-5.998-16.49-16.258-49.452-8.982-86.566z"
          opacity=".4"
        />
        <path
          data-name="Tracé 144"
          d="M302.551 255.958s21.236 119.667 30.152 167.617c9.265 49.852 35.561 160.829 35.561 160.829l-21.724 11.167s-44.932-134.01-50.289-159.154c-14.168-52.535-54.755-181.659-54.755-181.659z"
          fill="#263238"
        />
        <path
          data-name="Tracé 145"
          d="M372.767 589.068l-26.4 10.522-5.13-9.2 28.25-11.29z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 146"
          d="M253.623 592.906h-27.727l-1.605-9.737 29.35-.995z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 147"
          d="M236.681 250.867l-2.966 5.741c-.227.436.314.907 1.064.925l68.052 1.344c.593 0 1.082-.279 1.117-.646l.611-5.776c0-.4-.454-.75-1.1-.768l-65.731-1.294a1.134 1.134 0 0 0-1.047.474z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 148"
          d="M245.474 258.37h-1.745c-.349 0-.628-.192-.593-.4l.872-7.521c0-.227.332-.4.681-.4h1.745c.349 0 .611.192.593.419l-.872 7.521c-.035.258-.332.399-.681.381z"
          fill="#263238"
        />
        <path
          data-name="Tracé 149"
          d="M288.138 259.204h-1.745c-.349 0-.611-.192-.576-.4l.855-7.521c0-.227.332-.4.7-.4h1.744c.366 0 .628.192.593.419l-.872 7.521c-.036.227-.35.402-.699.381z"
          fill="#263238"
        />
        <path
          data-name="Tracé 150"
          d="M320.245 171.386c1.972 3.333 4.066 6.98 6.212 10.312s4.275 6.857 6.526 10.173 4.572 6.6 6.98 9.754a114.775 114.775 0 0 0 7.416 8.882 16.769 16.769 0 0 0 7.5 4.188 53.672 53.672 0 0 0 10.888 1.884 141.335 141.335 0 0 0 23.817-.383l1.745 7.887a100.717 100.717 0 0 1-25.807 4.8 59.453 59.453 0 0 1-13.959-.89 37.707 37.707 0 0 1-7.364-2.251 25.738 25.738 0 0 1-7.207-4.642 130.314 130.314 0 0 1-17.292-20 200.8 200.8 0 0 1-13.816-21.844z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 151"
          d="M310.946 159.712c15.495 2.46 21.9 24.568 21.9 24.568l-18.618 17.938s-21.082-19.351-21.082-31.666c0-9.165 9.353-12.184 17.8-10.84z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 152"
          d="M310.946 159.712c15.495 2.46 21.9 24.568 21.9 24.568l-18.618 17.938s-21.082-19.351-21.082-31.666c0-9.165 9.353-12.184 17.8-10.84z"
          opacity=".2"
        />
        <path
          data-name="Tracé 153"
          d="M407.944 206.144c-.209 2.774-.576 5.514-.977 8.236s-.89 5.427-1.4 8.114-1.082 5.374-1.745 8.044-.541 5.514-3.49 7.521a2.46 2.46 0 0 1-2.949-.419 2.582 2.582 0 0 1-.384-.506c-1.745-3.315-.227-5.741.262-8.428s1.378-5.235 2.129-7.922 1.553-5.235 2.408-7.835 1.745-5.235 2.809-7.73a1.841 1.841 0 0 1 3.333.925z"
          fill="#263238"
        />
        <path
          data-name="Tracé 154"
          d="M408.171 209.093l-5.235-.856c-.785-.14-1.169-1.1-.872-2.146a2.279 2.279 0 0 1 1.972-1.745l5.235.872c.785.122 1.169 1.082.872 2.146-.297 1.118-1.187 1.873-1.972 1.729z"
          fill="#263238"
        />
        <path
          data-name="Tracé 155"
          d="M416.896 167.686c-7.957-2.3-17.17 4.415-20.555 15.024s.314 21.079 8.271 23.382 17.17-4.432 20.573-15.024-.315-21.075-8.289-23.382zm-11.307 35.37c-6.718-1.937-9.824-10.749-6.98-19.683s10.609-14.588 17.31-12.651 9.841 10.749 6.98 19.683-10.609 14.587-17.31 12.651z"
          fill="#407bff"
        />
        <path
          data-name="Tracé 156"
          d="M407.438 204.155c-6.282-1.745-9.213-10.086-6.543-18.444s9.946-13.7 16.245-11.865 9.213 10.086 6.526 18.444-9.943 13.61-16.228 11.865z"
          fill="#407bff"
          opacity=".1"
        />
        <path
          data-name="Tracé 157"
          d="M384.562 224.658l17.1 3.193 1.745-14.343s-14.064.82-18.9 5.235z"
          fill="#ff8b7b"
        />
        <path
          data-name="Tracé 158"
          d="M405.955 227.817l3.019-9.527a1.466 1.466 0 0 0-.628-1.745l-4.886-3.089-1.745 14.343 2.356.907a1.483 1.483 0 0 0 1.884-.89z"
          fill="#ff8b7b"
        />
      </g>
    </svg>
  )
}

SvgAnalysis.propTypes = {}

export default SvgAnalysis
