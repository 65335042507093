import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CourseCard from "../components/home/course-card"
import SvgAnalysis from "../components/svg/analysis"

const IndexPage = ({ data }) => {
  const courses = data.allStrapiCourse.edges

  return (
    <Layout>
      <SEO title="Cours de sciences économiques et sociales" />
      <section className="jumbotron-home mb-12 md:mt-16 px-2">
        <div className={"flex flex-col md:flex-row rounded-lg bg-teal-300 py-4 px-10"}>
          <div className={"mt-4 md:mt-0 md:ml-6 py-4"}>
            <div>
              <h1 className={"font-bold text-xl md:text-4xl mb-4"}>
                Apprendre les Sciences Économiques et Sociales au Lycée
              </h1>
              <h2 className={"text-gray-800 text-lg mb-4 font-semibold tracking-normal"}>
                Cours en ligne et ressources pédagogiques pour lycéens
              </h2>
              <p>
                Ce site web propose des ressources pédagogiques et des cours en ligne pour les lycéens souhaitant approfondir leurs connaissances en Sciences Économiques et Sociales (SES). Vous trouverez ici des supports de cours couvrant les différents aspects de la discipline. Que vous cherchiez à renforcer vos bases, à préparer le baccalauréat ou simplement à découvrir de nouvelles notions, ce site vous accompagne dans votre apprentissage.
              </p>
              <Link to={"/cours"} className={"mt-4 bg-teal-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-teal-900 transition-all active:bg-teal-100"}>
                Découvrir les cours
              </Link>
            </div>
          </div>
          <div className={"order-first w-full md:order-last md:flex-shrink-0 md:w-7/12"}>
              <SvgAnalysis />
          </div>
        </div>
      </section>
      <section className={"px-2"}>
        <h2 className={"font-bold tracking-wider mb-5 px-5"}>
          Cours mis à jour récemment
        </h2>
        <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"}>
          {courses.map(course => {
            return <CourseCard key={course.id} course={course.node} />
          })}
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query course {
    allStrapiCourse(sort: {fields: chapters___updatedAt, order: DESC}) {
      edges {
        node {
          id
          title
          slug
          level {
            title
            slug
          }
          chapters {
            title
            content
          }
          featured_media {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`
